import React from "react";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import {
    Typography, Button, TableContainer, TableRow,
    TableCell, Table, TableHead, TableBody
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import ClientPagination from './Pagination';
import * as ROUTES from '../../../constants/routes';
import './Table.css';

const TableClient = ({ clientList = [] }) => {
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className='table-app-title-header'>ID</TableCell>
                            <TableCell className='table-app-title-header'>Empresa</TableCell>
                            <TableCell className='table-app-title-header'>Administrador</TableCell>
                            <TableCell className='table-app-title-header'>Licencias</TableCell>
                            <TableCell className='table-app-title-header'>Estado</TableCell>
                            <TableCell className='table-app-title-header'>Detalle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientList.length === 0 &&
                            <TableRow key='empty'>
                                <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                                    No hay resultados para la busqueda
                                </TableCell>
                            </TableRow>
                        }
                        {clientList.map(item => (
                            <TableRow key={item.id}>
                                <TableCell component="th" scope="row">
                                    {item.id}
                                </TableCell>
                                <TableCell component="th" scope="row" className='table-row-name'>
                                    {item.name}
                                </TableCell>
                                <TableCell className='table-row-name'>
                                    {item.user.email}
                                </TableCell>
                                <TableCell style={{ paddingLeft: 42 }} className='table-row-name'>
                                    {item.numberOfLicense}
                                </TableCell>
                                <TableCell>
                                    <div className='table-row-cell' style={{ color: item.disabled ? '#6C757D' : '#28A745' }}>
                                        <CircleIcon style={{ fontSize: 10, marginRight: 11 }} />
                                        <Typography>
                                            {item.disabled ? 'Archivado' : 'Activo'}
                                        </Typography>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <Button className='button-app-see-detail'
                                        component={Link}
                                        to={ROUTES.CLIENT_DETAIL.replace(':id', item.id)}>
                                        Ver
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ marginTop: 24 }}>
                <ClientPagination />
            </div>
        </>
    );
}

export default withRouter(TableClient);