import {
    getPaymentsURL, getSubscriptionByIdURL, getGPSubscriptionURL, postUpdateAmountURL, postManualPaymentURL, gpSessionURL, cardTokenizeURL, getSubscriptionPlansURL, gpcheckout, registerSubscriptionURL, submitSubscriptionURL
    , updateSubscriptionPlanURL
} from '../constants/apiUrls';
import { postAxios, getAxios, postgpAxios } from '../services/Axios';
import { logoutAction } from './AuthDuck'
import JSEncrypt from 'jsencrypt';
// constantes
const initialState = {
    user: null,
    permission: null,
    token: null,
    plans: null,
    payments: null,
    expiration: null,
    isLogged: false,
    isEmailSent: false,
    resetPassword: false,
    alertMessage: null,
    loading: false,
    error: null,
    redirectToThankYou: false,
    checkoutError: null,
    response: null,
    subscriptionGP: null,
};

// types
const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const AUTH_ERROR = 'AUTH_ERROR';
const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
const GET_GP_SUBSCRIPTION_SUCCESS = 'GET_GP_SUBSCRIPTION_SUCCESS';
const GET_GP_SUBSCRIPTION_CLEAN = 'GET_GP_SUBSCRIPTION_CLEAN';
const EDIT_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
const EDIT_PLANS_ERROR = 'GET_PLANS_ERROR';
const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
const EDIT_SUBSCRIPTION_AMOUNT_SUCCESS = 'EDIT_SUBSCRIPTION_AMOUNT_SUCCESS';
const EDIT_SUBSCRIPTION_AMOUNT_ERROR = 'EDIT_SUBSCRIPTION_AMOUNT_ERROR';
const SUBSCRIPTION_MANUAL_PAYMENT_SUCCESS = 'SUBSCRIPTION_MANUAL_PAYMENT_SUCCESS';
const SUBSCRIPTION_MANUAL_PAYMENT_ERROR = 'SUBSCRIPTION_MANUAL_PAYMENT_ERROR';
const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS';
const LOADING_SUBSCRIPTION = 'LOADING_SUBSCRIPTION';
const LOADING_SIGNUP = 'LOADING_SIGNUP';
const ALERT_MESSAGE = 'ALERT_MESSAGE';
const LENGUAGE_ID_SUCCESS = 'LENGUAGE_ID_SUCCESS';
const aesjs = require('aes-js');
var rsa_ = new JSEncrypt();
rsa_.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCohNAdtqUpN7mxelg9BFx66FOHs9vnZKL0Kt0TVbiI32oMdBO4gzdBEri3rNLPgqWY19NmbJ88TdDVAQX8GxWcF72/7M3d7p6UBpRrYEAuCpd6C696NWarR3uigNJ8dxUE3xGcXwVrpLror3/XK8Qakdz11cAUTb0DGbe/kq/yMQIDAQAB");

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_SIGNUP:
            return { ...state, loading: true, alertMessage: " Executing your subscription payment, please wait a moment.", error: null };
        case SIGNUP_SUCCESS:
            return {
                ...state, response: action.response, checkoutError: action.payload,
                error: null, isLogged: false, loading: false, redirectToThankYou: true
            };
        case GET_PLANS_SUCCESS:
            return {
                ...state, plans: action.payload, error: null, loading: false
            };
        case EDIT_PLANS_SUCCESS:
            return {
                ...state, response: action.payload, loading: false
            };
        case EDIT_PLANS_ERROR:
            return {
                ...state, response: action.payload, error: null, alertMessage: "Ha ocurrido un error alterando los campos.", loading: false
            };
        case GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state, subscription: action.payload, error: null, loading: false
            };
        case GET_GP_SUBSCRIPTION_SUCCESS:
            return {
                ...state, subscriptionGP: action.payload, error: null, loading: false
            };
        case GET_GP_SUBSCRIPTION_CLEAN:
            return {
                ...state, subscriptionGP: null, error: null, loading: false
            };
        case EDIT_SUBSCRIPTION_AMOUNT_SUCCESS:
            return {
                ...state, response: action.payload, loading: false
            };
        case EDIT_SUBSCRIPTION_AMOUNT_ERROR:
            return {
                ...state, response: action.payload, error: null, alertMessage: "Ha ocurrido un error actualizando el monto", loading: false
            };            
        case SUBSCRIPTION_MANUAL_PAYMENT_SUCCESS:
            return {
                ...state, response: action.payload, loading: false
            };
        case SUBSCRIPTION_MANUAL_PAYMENT_ERROR:
            return {
                ...state, response: action.payload, error: null, alertMessage: "Ha ocurrido un error en el cobro manual", loading: false
            };
        case PAYMENTS_SUCCESS:
            return {
                ...state, payments: action.payload,
                error: null, loading: false
            };
        case CHECKOUT_ERROR:
            return {
                ...state, error: action.payload, alertMessage: action.alert, response: action.response, loading: false
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case ALERT_MESSAGE:
            return { ...state, alertMessage: action.payload };
        case LENGUAGE_ID_SUCCESS:
            return { ...state, lenguageId: action.payload };
        default:
            return state;
    }
};

// actions

export const signUpAction = (subscription, monthlyBilling, licenseAmount, selectedPlanId) => async (dispatch) => {
    dispatch({ type: LOADING_SIGNUP });
    //constructed this as a result of having to change the date input fields to work in safari. 
    subscription.exp = subscription.expYear + "-" + subscription.expMonth;
    let gotSession = false;
    try {
        // const cardDataObject = {
        //     card: {

        //         cardHolder: subscription.cardHolderName,
        //         expirationDate: {
        //             year: Math.floor(subscription.exp.substring(2, 4)),
        //             month: Math.floor(subscription.exp.substring(5, 7))
        //         },
        //         "cardNumber": subscription.cardN,
        //         CVC: subscription.CVV,
        //         "nickname": "fos-sale" //more than 5 chars and less tan 50 chars
        //     },

        // };

        const cardObj =
        {
            CardHolder: subscription.cardHolderName,
            CardNumber: subscription.cardN,
            CVC: subscription.CVV,
            nickname: "fos-sale", //more than 5 chars and less tan 50 chars,
            year: Math.floor(subscription.exp.substring(2, 4)),
            month: Math.floor(subscription.exp.substring(5, 7)),
            subscriptionId: "subscriptionId",
            session: "",
            token: ""
        };
        //console.log(cardDataObject);
        //Encrypting Card data to lizst tokens

        const res = await postAxios(gpSessionURL, cardObj);
        ////console.log("session token response",res.data.response,res.data);
        if (res.data.statusCode === 200) {
            gotSession = true;
            //console.log("got session?", gotSession);
            //let responseData = JSON.parse(res.data.response);
            //let cardToken = JSON.parse(responseData.result.token);
            let responseData = res.data.response;
            let cardToken = responseData.result.token;
            let cardTokenResponse = res.data;
            ////console.log("token response : ", cardTokenResponse);
            ////console.log("token : ", cardToken);
            /*
                        const token = responseData.token;
                        const session = responseData.session;
                        localStorage.setItem('token', token);
                        localStorage.setItem('gpsession', session);
                        var EncryptedCardData = pack(cardDataObject, session);
                        
                        const headers = {
                            // 'Content-Type': 'application/json',
                            'liszt-token': token
                        }
                        const cardTokenRes = await postgpAxios(gpcheckout + "tokenize", {
                            session: session,
                            ld: EncryptedCardData.ld,
                            lk: EncryptedCardData.lk
                        }, {
                            headers: headers
                        });
                        ////console.log(cardTokenRes.data.status);
                        ////console.log(cardTokenRes.data.result.last_digits);
                       */
            ////console.log("card tokenization response status : ", cardTokenResponse.status);
            // //console.log(cardTokenResponse);
            let discountTerm = subscription.discountPeriod ? subscription.discountPeriod : "0";
            let cardTokenError = {
                message: "error on subscription payment process",
                status: cardTokenResponse.status,
                alert: "There was an error with your payment method please verify your information, or try another method.",
                details: cardTokenResponse
            }
            if (cardTokenResponse) {
                // //console.log("subscription submittal start ");
                let creationError = {
                    message: "error on registration process",
                    status: "",
                    alert: "",
                    details: ""
                }
                ////console.log("selected plan ", selectedPlanId ? selectedPlanId : 0);
                try {
                    //  //console.log("discountTerm", discountTerm, subscription.final, subscription.final < 1);
                    let FOSSubscriptionOnly = (discountTerm === "Permanent" && subscription.final < 1);

                    // GP subscriptions MUST have a payment, if discount is 100% and permanent, we manage the subscription. 

                    const createSubRes = await postAxios(submitSubscriptionURL, {
                        licenseAmount: licenseAmount,
                        firstName: subscription.firstName,
                        lastName: subscription.lastName,
                        email: subscription.email,
                        orgName: subscription.orgName,
                        cardHolderName: subscription.cardHolderName,
                        countryCodeId: subscription.countryCodeId,
                        cardLast4: responseData.result.lastDigits,
                        paymentBrand: responseData.brand,
                        exp: subscription.exp,
                        monthlyBilling: monthlyBilling,

                        expYear: subscription.exp.substring(5, 7),
                        expMonth: subscription.exp.substring(0, 4),
                        termsAcceptance: subscription.termsAcceptance,
                        subtotal: subscription.subtotal.replace('$', ''),
                        discount: subscription.discount ? subscription.discount : "0",
                        discountPeriod: discountTerm === "Permanent" ? "0" : "0" + discountTerm,//force to send  as string (parsed on BE)
                        planName: subscription.planName ? subscription.planName : "Startup",
                        SubscriptionPlanId: selectedPlanId ? selectedPlanId : 0,
                        final: subscription.final,
                        OrganizationId: 0,
                        token: cardToken,
                        FOSSubscriptionOnly: FOSSubscriptionOnly,
                        //customerId: "xxxx",

                    });
                    let unparsedSubscriptionsResponse = JSON.parse(createSubRes.data.response);
                    //console.log("createSubRes.data.response", unparsedSubscriptionsResponse);
                    let subscriptionsResponse = JSON.parse(unparsedSubscriptionsResponse.content);
                    //console.log("response content", subscriptionsResponse);
                    //console.log("response content initial payment", (subscriptionsResponse.result  && subscriptionsResponse.subscriptionId) && subscriptionsResponse.result.initialPayment);
                    //console.log("FOS sub?", FOSSubscriptionOnly);
                    let paymentAuthorization = "no payment effected";
                    if (!FOSSubscriptionOnly) {
                        paymentAuthorization = subscriptionsResponse.result.initialPayment && subscriptionsResponse.result.initialPayment.authorization;
                    }

                    //paymentAuthorization = (!FOSSubscriptionOnly && subscriptionsResponse.result.initialPayment.authorization) ? subscriptionsResponse.result.initialPayment.authorization : "no payment effected";
                    let additionalInfo = unparsedSubscriptionsResponse.additionalInfo;
                    //console.log("subscription submittal response : ", subscriptionsResponse.status, subscriptionsResponse);
                    //var emailExists = subscriptionsResponse.message == "The user's email has already been registered" ? subscriptionsResponse.message : "";
                    creationError = {
                        message: "error on subscription payment process",
                        status: subscriptionsResponse.status,
                        alert: emailExists ? emailExists : "There was an error with your payment method, please verify your information, or try another method.",
                        details: subscriptionsResponse
                    }
                    ////console.log(creationError);
                    if (subscriptionsResponse && subscriptionsResponse.status >= 200 && subscriptionsResponse.status < 300) {
                        //console.log("recording account on system, previous response :", subscriptionsResponse);

                        const registerSubRes = postAxios(registerSubscriptionURL, {
                            GPsubscriptionId: subscriptionsResponse ? subscriptionsResponse.subscriptionId : "",
                            firstName: subscription.firstName,
                            lastName: subscription.lastName,
                            email: subscription.email,
                            orgName: subscription.orgName,
                            cardHolderName: subscription.cardHolderName,
                            countryCodeId: subscription.countryCodeId,
                            cardLast4: responseData.result.lastDigits,
                            paymentBrand: responseData.brand,
                            RNN: paymentAuthorization && paymentAuthorization ? paymentAuthorization : "",
                            exp: subscription.exp,
                            FOSSubscriptionOnly: FOSSubscriptionOnly,
                            expYear: subscription.exp.substring(5, 7),
                            expMonth: subscription.exp.substring(0, 4),
                            termsAcceptance: subscription.termsAcceptance,
                            subtotal: subscription.subtotal.replace('$', ''),
                            discount: subscription.discount ? subscription.discount : "0",
                            discountPeriod: discountTerm === "Permanent" ? "-1" : "0" + discountTerm,//force to send  as string (parsed on BE)
                            planName: subscription.planName ? subscription.planName : "Startup",
                            SubscriptionPlanId: selectedPlanId ? selectedPlanId : "0",
                            monthlyBilling: monthlyBilling,
                            final: subscription.final,
                            OrganizationId: additionalInfo && additionalInfo.customerId ? additionalInfo.customerId : 0,

                        });


                        return dispatch({
                            type: SIGNUP_SUCCESS,
                            response: "Succesfully created subscription, you will receive an email confirmation shortly",
                            payload: "Succesfully created subscription, you will receive an email confirmation shortly",
                        });

                    }
                    else {
                        //console.log("error message", subscriptionsResponse.message);

                        throw new Error(creationError);
                    }
                }
                catch (e) {
                    //console.log("error message", e, e.message);
                    var emailExists = e.message == "The user's email has already been registered" ? e.message : "";
                    creationError = {
                        message: "error on subscription payment process",
                        status: e.status,
                        subscriptionError: true,
                        alert: emailExists ? "An error occured while registering your account: " + emailExists : "There was an error with your payment method, please verify your information, or try another method.",
                        details: e.message
                    }
                    throw new Error(creationError);
                }
            }
            else {

                //console.log("error on card tokenization");
                // //console.log(cardTokenResponse.status);
                ////console.log(cardTokenResponse);
                throw new Error(cardTokenError);
            }


        }

        else {
            let sessionError = {
                message: res.data.response == "404" ? "error connecting to payment gateway, please try again later or contact us directly." : "There was an error with your payment method please verify your information, or try another method.",
                status: 500,
                details: "unable to get greenpay session"
            }
            throw new Error(sessionError);
        }
    } catch (error) {
        ////console.log("caught error", error);
        if (error.response && error.response) {
            //console.log("error response", error.response, error.response.data);
            gotSession = error.response.data && error.response.data.response.errorCode == "500";
        }
        //in case an error is triggered before step error is composed, defaulting to 2 possible error messages.

        var messageError = !gotSession ? "A connection error occured, please try again later." : "There was an error with your payment method, please verify your information, or try another method.";
        if (error.subscriptionError) {
            messageError = error.alert;
        }
        if (error.status === 403) {

            messageError = "subscription:internalError";
        }

        dispatch({
            type: CHECKOUT_ERROR,
            payload: error.message ? error.message : messageError,
            alert: messageError
        });
    }
};

export const getPlans = (filterBy = [], page = 0, pageSize = 1000, loading = false, service = "") => async (dispatch, getState) => {
    try {

        //dispatch({ type: LOADING_SUBSCRIPTION });
        const res = await postAxios(getSubscriptionPlansURL, {
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "service": service,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            //  (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: GET_PLANS_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: AUTH_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}
export const editPlan = (plan) => async (dispatch, getState) => {
    try {
        dispatch({ type: LOADING_SUBSCRIPTION });

        const res = await postAxios(updateSubscriptionPlanURL, {
            Id: plan.id,
            name: plan.name,
            userQuantity: plan.userQuantity,
            perUserCost: plan.perUserCost,
            yearlyCost: parseFloat(plan.yearlyTotal),
            yearlyTotal: parseFloat(plan.yearlyTotal),
            monthlyCost: parseFloat(plan.monthlyTotal),
            monthlyTotal: parseFloat(plan.monthlyTotal),

        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            //  (token && expiration) && dispatch(renewTokenAction(token, expiration));
            dispatch(getPlans());
            return dispatch({
                type: EDIT_PLANS_SUCCESS,
                payload: res.data.response,

            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: EDIT_PLANS_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}

export const getSubscriptionByOrgId = () => async (dispatch, getState) => {
    try {

        dispatch({ type: LOADING_SUBSCRIPTION });
        const { user } = getState().auth;
        const res = await getAxios(getSubscriptionByIdURL, { id: user.organizationId });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            //  (token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: GET_SUBSCRIPTION_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: AUTH_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}

export const getGPSubscription = (organizationId) => async (dispatch) => {
    try {

        dispatch({ type: LOADING_SUBSCRIPTION });
        const res = await getAxios(getGPSubscriptionURL + organizationId);
        if (res.status === 200) {
            return dispatch({
                type: GET_GP_SUBSCRIPTION_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: AUTH_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}

export const cleanGPSubscription = () => async (dispatch) => {
    return dispatch({
        type: GET_GP_SUBSCRIPTION_CLEAN
    });
}

export const postUpdateAmount = (subsInfo) => async (dispatch) => {
    try {
        let url = postUpdateAmountURL + '?subscriptionId=' + subsInfo.subscription + '&amount=' + subsInfo.newAmount;
        const res = await postAxios(url);
        //console.log('postUpdateAmount', res);
        if (res && res.status === 200) {

            dispatch(getGPSubscription(subsInfo.organizationId));
            //alert('El monto ha sido actualizado');
            return dispatch({
                type: EDIT_SUBSCRIPTION_AMOUNT_SUCCESS,
                payload: res.data.response,
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: EDIT_SUBSCRIPTION_AMOUNT_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}



export const postManualPayment = (subsInfo) => async (dispatch) => {
    try {
        let url = postManualPaymentURL + '?id=' + subsInfo.subscription;
        const res = await postAxios(url);
        if (res && res.status === 200) {
            dispatch(getGPSubscription(subsInfo.organizationId));
            //alert('El monto ha sido actualizado');
            return dispatch({
                type: SUBSCRIPTION_MANUAL_PAYMENT_SUCCESS,
                payload: res.data.response,
            });
        }
        throw new Error();
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: SUBSCRIPTION_MANUAL_PAYMENT_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}

export const getPaymentsBySubscriptionId = (filterBy = [], page = 0, pageSize = 1000, loading = false, service = "") => async (dispatch, getState) => {
    try {
        dispatch({ type: LOADING_SUBSCRIPTION });
        const { user } = getState().auth;
        const res = await postAxios(getPaymentsURL, {
            "organizationId": user.organizationId,
            "page": page,
            "pageSize": pageSize,
            "filterBy": filterBy,
            "service": service,
            "orderBy": "",
            "orderDesc": true
        });
        if (res.status === 200) {
            const token = res.headers.refreshtoken;
            const expiration = res.headers.expirationToken;
            //(token && expiration) && dispatch(renewTokenAction(token, expiration));
            return dispatch({
                type: PAYMENTS_SUCCESS,
                payload: res.data.response,
                pages: res.data.totalPages,
                activePage: (res.data.totalPages < page + 1) ? 0 : page
            });
        }
    } catch (error) {
        if (error.response && error.response)
            dispatch({
                type: AUTH_ERROR,
                payload: error.response ? error.response.data.message : "auth:errorLoadingUser"
            });
    }
}

export const alertMessage = () => dispatch => {
    dispatch({
        type: ALERT_MESSAGE,
        payload: "newPasswordAlert"
    });
};
export const clearError = () => dispatch => {
    dispatch({
        type: AUTH_ERROR,
        payload: ''
    });
}

export const clearResponse = () => dispatch => {
    dispatch({ type: CLEAR_RESPONSE });
};


// 
function pack(obj, session, pair_) {
    var pair = (pair_ !== undefined) ? pair_ : generateAESPairs();
    var textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(obj));
    var aesCtr = new aesjs.ModeOfOperation.ctr(pair.k, new aesjs.Counter(pair.s));
    var encryptedBytes = aesCtr.encrypt(textBytes);
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    var returnObj = {
        session: session,
        ld: encryptedHex,
        lk: rsa_.encrypt(JSON.stringify(pair))
    };
    return returnObj;
}
function generateAESPairs() {
    var key = []
    var iv = 0;
    for (var k = 0; k < 16; k++) {
        key.push(Math.floor(Math.random() * 255))
    }
    // eslint-disable-next-line
    for (var k = 0; k < 16; k++) {
        iv = Math.floor(Math.random() * 255)
    }
    return {
        k: key,
        s: iv
    }
}
