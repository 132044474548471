import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container, CardMedia, Grid, Typography, InputLabel,
    TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, Button, Alert
} from '@mui/material';
import { Link } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as CheckCircle } from '../../../assets/Icons/checkCircle.svg';
import { ReactComponent as DisableCircle } from '../../../assets/Icons/disableCircle.svg';
import TextFieldComponent from '../../../components/TextField';
import * as ROUTES from '../../../constants/routes';
import { getClientById, disableClient, getAppSettingById, updateAppSetting, updateAppSettingKeys, updateOrgWebsite, resendAdminLoginEmail } from '../../../redux/ClientDuck';
import { getGPSubscription, postUpdateAmount, cleanGPSubscription, postManualPayment } from '../../../redux/SubscriptionDuck';
import { getFileURL } from '../../../constants/apiUrls';
import './Detail.css';
import Envelope from '@mui/icons-material/Send'

const DetailClient = props => {

    const company = useSelector(store => store.client.company);
    const appSetting = useSelector(store => store.client.appSetting);
    const subscriptionGP = useSelector(store => store.subscription.subscriptionGP);

    const [error, setError] = useState({});
    const [confirmChange, setConfirmChange] = useState(false);
    const [textChanged, setTextChanged] = useState(false);

    const [subs, setSubs] = useState({
        subscription: 0,
        newAmount: '',
        organizationId: 0
    });

    const [subAmountError, setSubAmountError] = useState('');

    const [url, setUrl] = useState({
        url_android: '', url_ios: ''
    });
    const [website, setWebsite] = useState({
        website: ''
    });
    const [keys, setKeys] = useState({
        sender_id: '', server_key: '', json_file: ''
    });

    const onChange = e => {
        const { name, value } = e.target;
        setUrl({ ...url, [name]: value });
    };

    const onChangeWebsite = e => {
        setTextChanged(true);
        const { name, value } = e.target;
        setWebsite({ ...website, [name]: value });
    };

    const onChangeKeys = e => {
        const { name, value } = e.target;
        setKeys({ ...keys, [name]: value });
    };

    const dispatch = useDispatch();

    const dateFormat = () => {
        const a = new Date(company.created)
        return a.toLocaleString("es-CR", { day: 'numeric', month: 'long', year: 'numeric' });
    };

    const validationAppKeys = () => {
        /*var errors = { sender_id: '', server_key: '' };

        if (keys.sender_id === null || keys.sender_id.length === 0 ||
            /^\s+$/.test(keys.sender_id))
            errors.sender_id = "Sender Id requerida.";

        if (keys.server_key === null || keys.server_key.length === 0 ||
            /^\s+$/.test(keys.server_key))
            errors.server_key = "Server Key requerida.";

        setError({ ...error, ...errors });
        return !errors.sender_id && !errors.server_key; */

        var errors = { json_file: '' };

        if (keys.json_file === null || keys.json_file.length === 0 ||
            /^\s+$/.test(keys.json_file))
            errors.json_file = "Json File es requerido.";

        setError({ ...error, ...errors });
        return !errors.json_file;

    };

    const validationWebsite = () => {

        var errors = { website: '' };

        if (website.website === null || website.website.length === 0 ||
            /^\s+$/.test(website.website))
            errors.website = "Url del sitio es requerida.";
        else if (!isValidURL(website.website))
            errors.website = "La url es inválida.";

        setError({ ...error, ...errors });
        return !errors.website;
    };
    const validationAppConfig = () => {
        var errors = { url_android: '', url_ios: '' };

        if (url.url_android === null || url.url_android.length === 0 ||
            /^\s+$/.test(url.url_android))
            errors.url_android = "Url requerida.";
        else if (!isValidURL(url.url_android))
            errors.url_android = "La url es inválida.";

        if (url.url_ios === null || url.url_ios.length === 0 ||
            /^\s+$/.test(url.url_ios))
            errors.url_ios = "Url requerida.";
        else if (!isValidURL(url.url_ios))
            errors.url_ios = "La url es inválida.";

        setError({ ...error, ...errors });
        return !errors.url_ios && !errors.url_android;
    };

    const isValidURL = (url) => {
        const regExp = /^(ftp|http|https):\/\/[^ "]+$/;
        return regExp.test(url) ? true : false;
    };

    const modifyAppSetting = () => {
        if (validationAppConfig()) {
            dispatch(updateAppSetting(url));
        }
    };

    const AcceptWebChange = () => {
        setConfirmChange(true);
    };

    const ChangeDefaultWebsite = () => {
        if (validationWebsite()) {
            dispatch(updateOrgWebsite(website, company.id));
        }
    };

    const ResetDefaultWebsite = () => {
        company && company.defaultSubdomain && setWebsite({ ...website, website: company.defaultSubdomain });
        setConfirmChange(false);
        setTextChanged(false);
    };

    const modifyAppSettingKeys = () => {
        if (validationAppKeys()) {
            dispatch(updateAppSettingKeys(keys));
        }
    };

    const onChangeAmount = e => {
        if (company && subscriptionGP) {
            setTextChanged(true);

            const { value } = e.target;
            setSubs({
                subscription: subscriptionGP.id,
                newAmount: value,
                organizationId: company.id
            });
        }
    };

    const manualPayment = () => {
        let message = "¿Estás seguro de realizar un cobro manual?"
        if (window.confirm(message) && subs && subs.subscription && subs.organizationId) {
            subscriptionGP.total_revenue = "Actualizando...";
            dispatch(postManualPayment(subs));
        }
    }

    const isNumeric = (value) => {
        return /^-?\d+(\.\d+)?$/.test(value);
    }

    const modifySubscriptionAmount = () => {
        let message = "¿Estás seguro de actualizar el monto de la subscripción?"
        if (window.confirm(message)) {
            if (subs && subs.organizationId > 0) {

                if (subs.newAmount == null || subs.newAmount == '') {
                    setSubAmountError('El nuevo monto es requerido.');
                    return;
                }

                if (isNumeric(subs.newAmount)) {
                    let newValue = parseFloat(subs.newAmount);

                    setSubs(prevState => ({
                        ...prevState,
                        newAmount: newValue
                    }));

                    if (subscriptionGP)
                        subscriptionGP.amount = "Actualizando...";

                    dispatch(postUpdateAmount(subs));
                }
                else {
                    setSubAmountError('El nuevo monto es inválido.');
                }
            }
            else
                setSubAmountError('El nuevo monto es requerido.');
        }
    };

    useEffect(() => {
        dispatch(cleanGPSubscription());
        const { id } = props.match.params;
        dispatch(getClientById(id));
        dispatch(getGPSubscription(id));
    }, [dispatch, props.match.params]);

    useEffect(() => {
        setConfirmChange(false);
        setTextChanged(false);
        company && company.defaultSubdomain && setWebsite({ ...website, website: company.defaultSubdomain });
        if (company && company.appSettingId) {
            dispatch(getAppSettingById(company.appSettingId));
        }
    }, [dispatch, company]);

    useEffect(() => {
        if (subscriptionGP != null && company != null) {
            setSubs({
                subscription: subscriptionGP.id,
                organizationId: company.id,
                newAmount: 0,
            });
        }
    }, [subscriptionGP, company]);

    useEffect(() => {
        if (appSetting) {
            setUrl({
                url_android: appSetting.googlePlayLink ? appSetting.googlePlayLink : '',
                url_ios: appSetting.appStoreLink ? appSetting.appStoreLink : ''
            });
        }
    }, [appSetting]);

    useEffect(() => {
        if (appSetting) {
            setKeys({
                sender_id: appSetting.senderId ? appSetting.senderId : '',
                server_key: appSetting.serverKey ? appSetting.serverKey : '',
                json_file: appSetting.jsonFirebase ? appSetting.jsonFirebase : ''
            });
        }
    }, [appSetting]);

    return company && company.id ? (
        <Container>
            <Button component={Link} to={ROUTES.CLIENT}
                underline="none"
                className='button-back'>
                <ArrowBackIosIcon />
                {company.name}
            </Button>
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                            {company.fileId &&
                                <CardMedia component="img"
                                    alt={company.name}
                                    image={getFileURL + company.fileId}
                                    sx={{
                                        width: 153, height: 86
                                    }}
                                />
                            }
                            <div style={{ marginLeft: 37 }}>
                                <Typography className="company-name" style={{ margin: '4px 0px' }}>
                                    {company.name}
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: company.disabled ? '#6C757D' : '#28A745',
                                    margin: '9px 0px'
                                }}>
                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                    <Typography className='company-status'>
                                        {company.disabled ? 'Archivado' : 'Activo'}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Detalles de la Empresa
                    </Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="header-cell">
                                    <TableCell className="detail-table-cell" align="right">Descripción</TableCell>
                                    <TableCell sx={{ width: '78%' }} className="detail-table-cell">Detalle</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'id'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell" >
                                        Empresa
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id10'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        ID
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id2'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Estado
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: company.disabled ? '#6C757D' : '#28A745',
                                        }}>
                                            <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                            <Typography>{company.disabled ? 'Archivado' : 'Activo'}</Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id3'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Licencias
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.numberOfLicense} Licencias
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id4'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Usuarios
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.usersCount} Usuarios Internos Activos
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id5'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Clientes
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.clientsCount} Clientes
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id6'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Personal del cliente
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.clientStaffCount} Usuarios
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id7'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Administrador
                                    </TableCell>
                                    <TableCell className="detail-table-cell" style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <label>
                                            {company.users && company.users[0].firstName + ' ' + company.users[0].lastName}
                                        </label>
                                        <label>
                                            {company.users && company.users[0].email}
                                        </label>

                                        {company.usersCount <= 1 && company.clientsCount <= 1 && company.clientStaffCount <= 1 &&
                                            <Button className='button-active-inactive' variant="text" style={{ width: "300px", marginTop: "5px" }}
                                                startIcon={<Envelope></Envelope>}
                                                onClick={() => dispatch(resendAdminLoginEmail(company.id))}>
                                                {"Reenviar correo de Registro"}
                                            </Button>}

                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id8'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Subdominio
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.defaultSubdomain}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id9'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Creado
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {dateFormat()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Subdominio
                    </Typography>
                    <div style={{ width: '48%', marginTop: 24 }}>
                        <TextFieldComponent name="website"
                            callback={onChangeWebsite}
                            valueInput={website && website.website}
                            errorInput={error.website}
                        />
                        <Alert severity="error" className='change-pass-alert' style={{ marginBottom: "35px" }}> Modificar este valor con discreción, pues modifica directamente el subdominio al cual esta vinculado el cliente.</Alert>
                        {!confirmChange && textChanged &&
                            <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                onClick={() => AcceptWebChange()}>
                                Confirmar
                            </Button>

                        }
                        {confirmChange &&
                            <div>

                                <Button variant="contained" className="button-app-delete" style={{ marginRight: 18 }}
                                    onClick={() => ChangeDefaultWebsite()}>
                                    Cambiar
                                </Button>
                                <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                    onClick={() => ResetDefaultWebsite()}>
                                    Cancelar
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Licencias
                    </Typography>
                    <div style={{ width: '48%', marginTop: 24 }}>
                        <InputLabel className="label-app">Cantidad de licencias</InputLabel>
                        <TextFieldComponent name="numberOfLicense"
                            callback={() => null} disabled
                            valueInput={company.numberOfLicense}
                        />
                    </div>
                </div>
                {company.appSettingId &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">
                            Enlaces
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">Android</InputLabel>
                                <TextFieldComponent name="url_android"
                                    callback={onChange.bind(this)}
                                    valueInput={url.url_android}
                                    placeholder='Url Android'
                                    errorInput={error.url_android}
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">iOS</InputLabel>
                                <TextFieldComponent name="url_ios"
                                    callback={onChange.bind(this)}
                                    valueInput={url.url_ios}
                                    placeholder='Url iOS'
                                    errorInput={error.url_ios}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 15 }}>
                            <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                onClick={() => modifyAppSetting()}>
                                Enviar
                            </Button>
                        </div>
                    </div>
                }
                {company.appSettingId &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">
                            Firebase App keys
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '48%', display: 'none' }}>
                                <InputLabel className="label-app">Sender Id</InputLabel>
                                <TextFieldComponent name="sender_id"
                                    callback={onChangeKeys.bind(this)}
                                    valueInput={keys.sender_id}
                                    placeholder='sender ID'
                                    errorInput={error.sender_id}
                                />
                            </div>
                            <div style={{ width: '48%', display: 'none' }}>
                                <InputLabel className="label-app">Server Key</InputLabel>
                                <TextFieldComponent name="server_key"
                                    callback={onChangeKeys.bind(this)}
                                    valueInput={keys.server_key}
                                    maxLength="200"
                                    row="4"
                                    placeholder='server Key'
                                    errorInput={error.server_key}
                                />
                            </div>
                            <div style={{ width: '96%' }}>
                                <InputLabel className="label-app">Firebase service account</InputLabel>
                                <TextFieldComponent name="json_file"
                                    callback={onChangeKeys.bind(this)}
                                    valueInput={keys.json_file}
                                    maxLength="5000"
                                    row="4"
                                    placeholder='Firebase service account'
                                    errorInput={error.json_file}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 15 }}>
                            <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                onClick={() => modifyAppSettingKeys()}>
                                Enviar
                            </Button>
                        </div>
                    </div>
                }
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Opciones
                    </Typography>
                    <Button className='button-active-inactive' variant="text"
                        startIcon={company.disabled ? <CheckCircle /> : <DisableCircle />}
                        onClick={() => dispatch(disableClient(company.id))}
                    >
                        {company.disabled ? 'Activar' : 'Suspender'}
                    </Button>
                </div>

                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Subscripcción
                    </Typography>
                    {subscriptionGP ?
                        <>
                            {subscriptionGP.status != 'NOT EXIST' ?
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow className="header-cell">
                                                        <TableCell className="detail-table-cell" align="right">Descripción</TableCell>
                                                        <TableCell sx={{ width: '78%' }} className="detail-table-cell">Detalle</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={'id'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell" >
                                                            Descripción
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.description}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'id10'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell">
                                                            Monto
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.amount}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'id2'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell">
                                                            Recurrencia
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.cadence}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'id2'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell">
                                                            Estado
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.status}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'id3'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell">
                                                            Fecha Inicio
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.startDateDisplay}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'id4'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell">
                                                            Próxima Fecha
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.nextPaymentDateDisplay}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow key={'id5'} className="row-org-detail">
                                                        <TableCell component="th" scope="row" className="description-table-cell">
                                                            Ingreso Totales
                                                        </TableCell>
                                                        <TableCell className="detail-table-cell">
                                                            {subscriptionGP.total_revenue}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    {(subscriptionGP.status == 'ACTIVE' || subscriptionGP.status == 'ON_HOLD') &&
                                        <>
                                            <Typography className="app-form-title" style={{ marginTop: 25 }}>
                                                Actualizar Monto
                                            </Typography>
                                            <div style={{ width: '48%', marginTop: 24 }}>
                                                <InputLabel className="label-app">Monto</InputLabel>
                                                <TextFieldComponent name="subscriptionGP_newAmount"
                                                    type="number"
                                                    callback={onChangeAmount}
                                                    valueInput={subscriptionGP.newAmount}
                                                    placeholder='Nuevo monto de la subscripción'
                                                    errorInput={subAmountError}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', marginTop: 15 }}>
                                                <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                                    onClick={() => modifySubscriptionAmount()}>
                                                    Enviar
                                                </Button>
                                            </div>
                                            <Typography className="app-form-title" style={{ marginTop: 25 }}>
                                                Cobro Manual
                                            </Typography>
                                            <div style={{ display: 'flex', marginTop: 15 }}>
                                                <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                                    onClick={() => manualPayment()}>
                                                    Cobro Manual
                                                </Button>
                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        La Subscripcción no existe en greenpay.
                                    </div>
                                </>
                            }
                        </>
                        :
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                Obteniendo Información de greenpay....
                            </div>
                        </>
                    }
                </div>

            </Grid>
        </Container>
    ) : null;
}

export default withRouter(DetailClient);